import Hub from './components/Hub';
import Banner from './components/Banner';
import Intro from './components/Introduction';
import { Routes, Route, useLocation } from 'react-router-dom';
import * as React from 'react';
import { authData } from './data/authdata';
import { challenges } from './data/challenges';
import { settingdata } from './data/settingsdata';
import Deblocage from './components/Deblocage';
import Enigme from './components/Enigme';
import Cookies from 'js-cookie';
import Hint from './components/Hint';
import Final from './components/Final';
import Admin from './components/Admin';
import LegalNotice from './components/LegalNotice';
import EndTimerDialog from './components/EndTimerDialog';

let secondTimeEndGame = false;

export default function App() {
  const [open, setOpen] = React.useState(false);
  const [time, setTime] = React.useState(settingdata.timeGame);
  const [intervalID, setIntervalID] = React.useState();
  const location = useLocation()

  const updateHintCredit = (newTime) => {
    let maxGiveAtTime = 0;
    for (const key in settingdata.hintCreditToGiveAtTime) {
      if ((newTime - 1) <= key) {
        if (maxGiveAtTime <= settingdata.hintCreditToGiveAtTime[key]) {
          maxGiveAtTime = settingdata.hintCreditToGiveAtTime[key];
        }
      }
    }
    //update le cookie du hintCreditmaxGiveAtTime
    Cookies.set('hintCreditmaxGiveAtTime', maxGiveAtTime, { path: '/' })
  }

  React.useEffect(() => {
    if (location.pathname === "/") {
      return;
    }

    const retrieveTime = () => Cookies.get('time');

    if (Cookies.get('hintcreditUse') === undefined) {//le cookie du hintcreditUse n'existe pas on le cree
      Cookies.set('hintcreditUse', settingdata.hintCreditUse, { path: '/' })
    }

    if (Cookies.get('hintCreditmaxGiveAtTime') === undefined) {//le cookie du hintCreditmaxGiveAtTime (les indices données depuis le debut) n'existe pas on le cree
      Cookies.set('hintCreditmaxGiveAtTime', 0, { path: '/' })
    }

    if (Cookies.get('EndGame') === undefined) {//le cookie du endGame n'existe pas on le cree
      Cookies.set('EndGame', 0, { path: '/' });
      //console.log('Init cookie endGame');
    }

    const cookieTime = retrieveTime();
    if (cookieTime) {
      setTime(cookieTime);
    }

    const interval = setInterval(() => {

      setTime(time => {
        if (!secondTimeEndGame) {//(secondTimeEndGame c'est pour retarder l'actualisation d'une seconde lorsque le jeu s'arrete)

          if (parseInt(Cookies.get('EndGame')) !== 0) {// si le jeu est fini on actualise plus le temps 
            secondTimeEndGame = true;
          }

          if (time >= 0) {
            const newTime = time - 0.1;
            Cookies.set('time', newTime, { path: '/' });

            //Les régles des crédits d'indices sont paramétrés dans le fichier settingsdata.js
            updateHintCredit(newTime)

            return newTime;

          } else {
            setOpen(true);
            clearInterval(interval);
          }

        }
        return time;

      });

    }, 100);

    setIntervalID(interval);

    return () => clearInterval(interval);
  }, [location]);

  //Formatage du temps qui passe en affichage numérique (en même temps analogique...)
  const formatTime = () => {
    let minutes = Math.floor(time / 60);
    let seconds = Math.floor(time % 60);
    if (seconds < 10) {
      seconds = "0" + seconds
    }
    return `${minutes}:${seconds}`;
  }

  //Utilisateur authentifié ?
  const isAuthenticated = (index) => {
    let passtoken = Cookies.get('auth' + authData[index].enigme + 'token');
    if (authData[index].password === passtoken) {
      return true
    }
    return false
  }

  //Change le titre en fonction de la localisation
  const changeTitle = (location) => {
    let location_clean = location.split("/")[1]
    if (location_clean !== "") {
      location_clean = "/" + location_clean;
    } else {
      location_clean = location;
    }
    switch (location_clean) {
      case "/hub":
        return "Hub";
      case "/network":
        return "Administration Réseau";
      case "/social":
        return "Communication Digitale";
      case "/bdd":
        return "Base de Données et Data"; 
      case "/hardware":
        return "HARDWARE";
      case "/code":
        return "Code et Programmation";
      case '/hint':
        return "Indice"
      case '/final':
        return "Enigme finale"
      case '/admin':
        return "Admin"
      case '/':
        return "PARALLAXE 2050"
      default:
        return "PARALLAXE 2050";
    }
  }
  
  //Récupère l'état d'un cookie
  const fetchState = (index) => {
    let saveState = Cookies.get(challenges[index].enigme + '_state');
    return saveState ? parseInt(saveState) : 0
  }

  //Las Vegas is the best state
  //Récupère tout les états contenu dans les cookies
  const getAllstates = () => {
    return challenges.map((challenge, index) => {
      return fetchState(index);
    });
  }


  return (
    <>
      <Banner stepid={getAllstates()} title={changeTitle(location.pathname)} time={formatTime()} />
      <EndTimerDialog
        open={open}
        setOpen={setOpen}
      />
      <Routes>
        <Route path="/" element={<Intro
          url="/videos/dora_introduction.mp4"
          title="Introduction"
          transcription="Le Greenternet est un nouveau réseau plus sobre et plus responsable. Malheureusement, il ne plaît pas à tout le monde. Le réseau est actuellement attaqué par un virus lancé par une armée de trolls. Les membres du Greenternet, Dora, Marjorie, Leatitia, Marie et Marguerite ont besoin de votre aide. Depuis le Hub, vous pouvez vous connecter à un des cinq postes pour aider l'équipe à sauver le Greenternet. Bonne chance !"
        />} />
        <Route path="/hub" element={<Hub stepid={getAllstates()} time={formatTime()} />} />
        <Route path="/network/:stepid" element={isAuthenticated(0) ? <Enigme index={0} /> : <Deblocage index={0} />} />
        <Route path="/social/:stepid" element={isAuthenticated(1) ? <Enigme index={1} /> : <Deblocage index={1} />} />
        <Route path="/bdd/:stepid" element={isAuthenticated(2) ? <Enigme index={2} /> : <Deblocage index={2} />} />
        <Route path="/hardware/:stepid" element={isAuthenticated(3) ? <Enigme index={3} /> : <Deblocage index={3} />} />
        <Route path="/code/:stepid" element={isAuthenticated(4) ? <Enigme index={4} /> : <Deblocage index={4} />} />
        <Route path="/final" element={<Final intervalID={intervalID} />} />
        <Route path="/hint" element={<Hint />} />
        <Route path="/admin" element={isAuthenticated(5) ? <Admin intervalID={intervalID} /> : <Deblocage index={5} />} />
        <Route path="/LegalNotice" element={<LegalNotice />} />
      </Routes>
    </>
  )
}